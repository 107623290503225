/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */

import React from 'react';
import { func, number, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'yoda-core-components/lib/components/Icon';
import ArrowRight from 'yoda-core-components/lib/assets/svg/ArrowRight.svg';
import ArrowLeft from 'yoda-core-components/lib/assets/svg/ArrowLeft.svg';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import updateMultiSelectFacetInfo from '../../actions/MultiSelectFilterActions';
import { setFailoverCanonicalInfo } from '../../actions/GalleryAction';

const Pagination = ({ containerTheme, selectedPageNumber, totalPages, onPageChange }) => {
    const {
        deviceType: { isMobile = false } = {},
        featureFlags: { enableMultiSelectFilters = false, enableCanonicalFallback = true } = {},
    } = useSelector(({ context = {} }) => context);

    const dispatch = useDispatch();

    const range = (start, end) => {
        const length = end - start + 1;
        const rangeReturn = [];

        for (let i = start; i < start + length; i += 1) {
            rangeReturn.push(i);
        }

        return rangeReturn;
    };

    const getPageItemsMobile = () => {
        const boundaryCount = 1;
        const firstPageCount = 4;
        let itemList;
        const startPages = range(1, Math.min(boundaryCount, totalPages));

        /* istanbul ignore next */
        if (selectedPageNumber === 1 || totalPages <= firstPageCount) {
            itemList = range(1, Math.min(firstPageCount, totalPages));
        } else {
            itemList = [
                ...startPages,
                ...(selectedPageNumber > 2 ? ['start-ellipsis'] : []),
                ...(selectedPageNumber > 1 && selectedPageNumber !== totalPages
                    ? range(selectedPageNumber, selectedPageNumber + 1)
                    : []),
                ...(selectedPageNumber === totalPages
                    ? range(selectedPageNumber - 2, selectedPageNumber)
                    : []),
            ];
        }

        return itemList;
    };

    const getPageItems = () => {
        const boundaryCount = 1;
        const siblingCount = 1;
        const startPages = range(1, Math.min(boundaryCount, totalPages));
        const endPages = range(
            Math.max(totalPages - boundaryCount + 1, boundaryCount + 1),
            totalPages
        );

        const siblingsStart = Math.max(
            Math.min(
                selectedPageNumber - siblingCount,
                totalPages - boundaryCount - siblingCount * 2 - 1
            ),
            boundaryCount + 2
        );

        const siblingsEnd = Math.min(
            Math.max(selectedPageNumber + siblingCount, boundaryCount + siblingCount * 2 + 2),
            endPages[0] - 2
        );

        /* istanbul ignore next */
        const itemList = [
            ...startPages,
            // Start ellipsis
            ...(siblingsStart > boundaryCount + 2
                ? ['start-ellipsis']
                : boundaryCount + 1 < totalPages - boundaryCount
                ? [boundaryCount + 1]
                : []),
            // Sibling pages
            ...range(siblingsStart, siblingsEnd),
            // End ellipsis
            ...(siblingsEnd < totalPages - boundaryCount - 1
                ? ['end-ellipsis']
                : totalPages - boundaryCount > boundaryCount
                ? [totalPages - boundaryCount]
                : []),

            ...endPages,
        ];

        return itemList;
    };

    const changeHandler = (event) => {
        enableCanonicalFallback &&
            dispatch(
                setFailoverCanonicalInfo({
                    isUpdateCanonicalUrl: true,
                })
            );
        enableMultiSelectFilters &&
            dispatch(
                updateMultiSelectFacetInfo({ scrollToTitle: true, enableProductsLoader: true })
            );
        onPageChange(Number(event));
    };

    const displayPaginationItems = () => {
        const arrayItems = isMobile ? getPageItemsMobile() : getPageItems();
        const items = [];

        for (let i = 0; i < arrayItems.length; i += 1) {
            const paginationTextItemActiveClasses = dt(['bg-gray-70', 'rounded-sm']);

            const paginationTextItemClasses = `${dt([
                'font-sans',
                'font-semibold',
                'no-underline',
                'w-8',
                'text-center',
                'lg:text-xlarge',
                'sm:text-normal',
                'float-left',
                'md:py-0.5',
                'px-0',
                'md:mr-3',
                'smOnly:py-1.5',
                'smOnly:mr-2',
                'last:mr-0',
                'pagination-item',
            ])} ${
                arrayItems[i] === 'start-ellipsis' || arrayItems[i] === 'end-ellipsis'
                    ? dt(['cursor-default'])
                    : dt(['cursor-pointer'])
            } ${selectedPageNumber === arrayItems[i] ? dt(['text-white']) : dt(['text-black'])}`;

            if (arrayItems[i] === 'start-ellipsis' || arrayItems[i] === 'end-ellipsis') {
                items.push(<div className={paginationTextItemClasses}>...</div>);
            } else
                items.push(
                    <div
                        onClick={() => changeHandler(arrayItems[i])}
                        className={`${
                            selectedPageNumber === arrayItems[i]
                                ? paginationTextItemActiveClasses
                                : ''
                        } ${paginationTextItemClasses}`}
                    >
                        {arrayItems[i]}
                    </div>
                );
        }
        return items;
    };

    const navigatonPagination = (targetPage) => {
        // This is added to fix..
        enableMultiSelectFilters &&
            dispatch(
                updateMultiSelectFacetInfo({ scrollToTitle: true, enableProductsLoader: true })
            );
        let goToPage = selectedPageNumber;
        /* istanbul ignore next */
        if (selectedPageNumber > 0) {
            goToPage = selectedPageNumber + targetPage;
        }
        onPageChange(goToPage);
    };

    const buttonClasses = dt([
        'font-sans',
        'font-semibold',
        'text-black',
        'sm:text-md',
        'md:text-md',
        'lg:text-lg',
    ]);

    return (
        <>
            {totalPages > 1 && (
                <div
                    className={`${dt([
                        'block',
                        'font-sans',
                        'mb-10',
                        'relative',
                        'text-md',
                        'w-full',
                        'text-center',
                    ])} ${containerTheme}`}
                >
                    <div className="pagination-section" id="gallery-pagination">
                        <div className={`${dt(['pb-2', 'text-center', 'pagination-right'])}`}>
                            {selectedPageNumber !== 1 && (
                                <button
                                    data-automation-id="product-pagination-left"
                                    className={buttonClasses}
                                    onClick={() => navigatonPagination(-1)}
                                    type="button"
                                >
                                    <span className={dt(['float-left'])}>
                                        <Icon
                                            className="text-black"
                                            height={isMobile ? '24' : '32'}
                                            width={isMobile ? '24' : '32'}
                                        >
                                            <ArrowLeft />
                                        </Icon>
                                    </span>
                                    Previous
                                </button>
                            )}
                            <div
                                className={`${dt([
                                    'inline-block',
                                    'md:px-7',
                                    'smOnly:px-3',
                                    'py-0',
                                    'pagination-container',
                                ])}`}
                            >
                                <div className={dt(['inline-flex'])}>
                                    {displayPaginationItems()}
                                </div>
                            </div>

                            {selectedPageNumber !== totalPages && (
                                <button
                                    data-automation-id="product-pagination-right"
                                    onClick={() => navigatonPagination(+1)}
                                    className={buttonClasses}
                                    type="button"
                                >
                                    {' '}
                                    Next
                                    <span className={dt(['float-right'])}>
                                        <Icon
                                            className={dt(['text-black'])}
                                            height={isMobile ? '24' : '32'}
                                            width={isMobile ? '24' : '32'}
                                        >
                                            <ArrowRight />
                                        </Icon>
                                    </span>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

Pagination.propTypes = {
    selectedPageNumber: number.isRequired,
    totalPages: number.isRequired,
    containerTheme: string,
    onPageChange: func,
};

Pagination.defaultProps = {
    containerTheme: null,
    onPageChange: null,
};

export default Pagination;
